import { Injectable } from '@angular/core';
import { IRestaurantRegistrationModel } from 'src/app/core/models/restaurant.model';
import { FileService } from 'src/app/core/services/file.service';
import { AppHttpClient } from 'src/app/core/services/http-call/http-call.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RestaurantRegistrationService {

  constructor(private httpClient: AppHttpClient) { }

  registerRestaurant(restaurantModel: IRestaurantRegistrationModel) {
    return this.httpClient.post(`${environment.apiUrl}v1/restaurant/register`, restaurantModel);
  }

  bankList(){
    return this.httpClient.get(`${environment.apiUrl}v1/bank/list`);
  }

  stateList(){
    return this.httpClient.get(`${environment.apiUrl}v1/state`);
  }
  cityList(stateId: number){
    return this.httpClient.get(`${environment.apiUrl}v1/state/${stateId}/cities`);
  }

  getRestaurantById(id: number) {
    return this.httpClient.get(`${environment.apiUrl}v1/restaurant/` + id);
  }

  saveRestaurant(id: number, restaurantModel: IRestaurantRegistrationModel) {
    return this.httpClient.put(`${environment.apiUrl}v1/restaurant/updateRegistration/` + id, restaurantModel);
  }

}
