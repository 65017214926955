export class ValidationHelper {

    static isValidEmail(inputText: string) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputText)) {
            return (true)
        }
        else
            return (false)
    }
    static isValidMobileNo(inputText: string) {
        var phoneno = /^\d{10}|d{11}$/;
        if (inputText.match(phoneno)) {
            return true;
        }
        else {
            return false;
        }
    }

    static isValidAccountNo(inputText: string) {
        var phoneno = /^\d{10}$/;
        if (inputText.match(phoneno)) {
            return true;
        }
        else {
            return false;
        }
    }
}