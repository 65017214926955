import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppHttpClient } from './http-call/http-call.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  API_URL = `${environment.apiUrl}v1/file/`;
  constructor(private httpClient: AppHttpClient) { }

  allowedTypes = ['JPG', 'jpg', 'PNG', 'png', 'JPEG', 'jpeg'];
  
  allowedDocTypes = ['docx', 'Doc', 'pdf', 'PDF'] 

  isValidImage(fileType: string) {
    return this.allowedTypes.includes(fileType)
  }

  
  isValidDoc(fileType: string) {
    return this.allowedDocTypes.includes(fileType)
  }


  upload(file: File, category: string) {
    let fileType = file.name.split('.').pop();
    if (this.isValidImage(fileType)) {
      const href = this.API_URL + category;
      var formData = new FormData();
      formData.append("file", file);
      return this.httpClient.post<any>(href, formData);
    }
    else {
      throw ('Only .PNG and .JPG files are allowed!');
    }
  }

  
  uploadDocAndImage(file: File, category: string) {
    let fileType = file.name.split('.').pop();
    if (this.isValidImage(fileType) || this.isValidDoc(fileType)) {
      const href = this.API_URL + category;
      var formData = new FormData();
      formData.append("file", file);
      return this.httpClient.post<any>(href, formData);
    }
    else {
      throw ('Only Documents or Image Files are allowed!');
    }
  }


  delete(fileUrl: string) {
    const href = this.API_URL;
    return this.httpClient.delete<any>(href, {
      params: new HttpParams().append("filePath", fileUrl)
    }
    );
  }
}
