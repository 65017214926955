export const environment = {
  production: true,
  appVersion: 'v1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: false,
  mapsAPIKey: 'AIzaSyB166EUus9MT9T8FkZTliW1R9oiYYzNKZU',
  apiUrl: 'https://api.immie.apptechasia.app/api/',
  firebaseConfig: {
    apiKey: "AIzaSyA_REjNC3YyQk7_uQ8RLRRfJeslem1VxLs",
    authDomain: "immie-1b844.firebaseapp.com",
    projectId: "immie-1b844",
    storageBucket: "immie-1b844.appspot.com",
    messagingSenderId: "540660140439",
    appId: "1:540660140439:web:0aac28cf9b21783dc2dcd2",
    measurementId: "G-NYM0DJM1CB",
    vapidKey: "BKI-Ut2G71acjVeSnSarxOOpS_exn_u2EqlPAm8P8Oh9mqnlpuLOyKvDuTjqn3E90nNOHVahYkKosrU9kEIK4uw"
  },
  restaurantShareLink: "https://gmealshub-testing.test-app.link/restaurant?slug="
};
