import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './alert/alert.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { ConfirmationPopupComponent } from './confirmation-popup/confirmation-popup.component';
import { ManageSchedulePopupComponent } from 'src/app/pages/restaurant-registration/manage-schedule-popup/manage-schedule-popup.component';
import { NgbDropdownModule, NgbTimepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RestaurantAccountInformationComponent } from './restaurant-account-information/restaurant-account-information.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgSelectModule } from '@ng-select/ng-select';



@NgModule({
  declarations: [AlertComponent, ConfirmationPopupComponent, ManageSchedulePopupComponent, RestaurantAccountInformationComponent, ImageCropperComponent],
  imports: [
    CommonModule,
    InlineSVGModule,
    NgbTimepickerModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.mapsAPIKey,
      libraries: ['places']
    }),
    NgbDropdownModule,
    NgbTooltipModule,
    ImageCropperModule,NgSelectModule
  ],
  exports: [AlertComponent, ManageSchedulePopupComponent, RestaurantAccountInformationComponent, ImageCropperComponent],
  entryComponents: [ManageSchedulePopupComponent]
})
export class SharedModule { }
