<!--begin::Alert-->
<div class="alert alert-custom alert-light-success fade show mb-4 mx-2" *ngIf="type == 'success'" role="alert">
    <div class="alert-icon">
        <span class="svg-icon svg-icon-3x svg-icon-success"
            [inlineSVG]="'./assets/media/svg/icons/Code/Done-circle.svg'" cacheSVG="true">
        </span>
    </div>
    <div class="alert-text font-weight-bold">
        {{message}}
    </div>
    <div class="alert-close">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">
                <i class="ki ki-close"></i>
            </span>
        </button>
    </div>
</div>
<!--end::Alert-->
<!--begin::Alert-->
<div class="alert alert-custom alert-light-danger fade show mb-4 mx-2" *ngIf="type == 'error'" role="alert">
    <div class="alert-icon">
        <span class="svg-icon svg-icon-3x svg-icon-danger" [inlineSVG]="'./assets/media/svg/icons/Code/Info-circle.svg'"
            cacheSVG="true">
        </span>
    </div>
    <div class="alert-text font-weight-bold" *ngIf="message">
        {{message}}
    </div>
    <div class="alert-close">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">
                <i class="ki ki-close"></i>
            </span>
        </button>
    </div>
</div>
<!--end::Alert-->