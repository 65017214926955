import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RestaurantRegistrationComponent } from './restaurant-registration.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { environment } from 'src/environments/environment';
import { CoreModule } from 'src/app/_metronic/core';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  declarations: [RestaurantRegistrationComponent],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule,
    NgbTimepickerModule,
    SharedModule,
    AgmCoreModule.forRoot({
      apiKey: environment.mapsAPIKey,
      libraries: ['places']
    }),
    NgSelectModule
  ], 
  entryComponents: [
    ]
})
export class RestaurantRegistrationModule { }
