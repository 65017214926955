import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {

  @Input()
  image: any;
  croppedImageUrl: any;
  croppedImageBlob: any;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  imageCropped(event: any) {
    this.croppedImageUrl = event.objectUrl;
    // event.blob can be used to upload the cropped image
    this.croppedImageBlob = event.base64;
  }

  saveImage() {
    const file = this.dataURLtoFile(this.croppedImageBlob, this.image.target.files[0].name);
    this.modal.close(file);
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

}
