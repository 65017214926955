// Angular
import { Pipe, PipeTransform } from '@angular/core';
import { format, parse } from 'date-fns';

/**
 * Returns only first letter of string
 */
@Pipe({
  name: 'timeFormatter',
})
export class TimeFormatterPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param value: any
   * @param args: any
   */
  transform(value: any, args?: any): any {
    if (value) {
      let dateString;
      let splitVal = value.split(':');
      if (splitVal.length < 3)
        dateString = parse(value, 'HH:mm', new Date());
      else
        dateString = parse(value, 'HH:mm:ss', new Date())
      return format(dateString, 'hh:mm a');
    } else
      return value;
  }
}
