<div class="card card-custom gutter-b">
    <div class="card-body">
        <image-cropper [imageChangedEvent]="image" [maintainAspectRatio]="true" [aspectRatio]="1 / 1" format="png"
            (imageCropped)="imageCropped($event)"
           ></image-cropper>


    </div>
    <div class="card-footer text-right">
        <button type="button" class="btn btn-primary mr-2" (click)="saveImage()"> Save </button>
        <button type="button" class="btn btn-default" (click)="modal.dismiss()"> Close </button>
    </div>
</div>