<div class="modal-content">
    <div class="modal-header">
        <div class="modal-title h4" id="example-modal-sizes-title-lg">
            <ng-container *ngIf="schedule">
                <ng-container *ngIf="schedule.id">Edit</ng-container>
                <ng-container *ngIf="!schedule.id">Create</ng-container>
                Schedule
            </ng-container>
        </div>
    </div>
    <div class="overlay overlay-block cursor-default modal-body">
        <ng-container *ngIf="isLoading">
            <div class="overlay-layer bg-transparent">
                <div class="spinner spinner-lg spinner-success"></div>
            </div>
        </ng-container>

        <ng-container *ngIf="schedule">

            <div class="form-group row">
                <div class="col-md-6 col-xl-5 offset-xl-1">
                    <label>Start Time</label>
                    <ngb-timepicker [(ngModel)]="schedule.startTime" meridian="true" #startTime="ngModel"
                        required></ngb-timepicker>
                    <span class="form-text text-danger" *ngIf="showError">
                        Please select the Start Time.
                    </span>
                </div>
                <div class="col-md-6 float-right col-xl-5">
                    <label>End Time</label>
                    <ngb-timepicker [(ngModel)]="schedule.endTime" #endTime="ngModel" meridian="true" required></ngb-timepicker>
                    <span class="form-text text-danger" *ngIf="showError">
                        Please select the End Time.
                    </span>
                    <span class="form-text text-danger" *ngIf="invalidTime">
                        End Time shoud be after the Start Time.
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <div class="col week-pill">
                    <ul class="nav nav-pills nav-pills-sm nav-dark-75">
                        <li class="nav-item" style="cursor: pointer;" *ngFor="let days of weekDays">
                            <a data-toggle="tab" (click)="handleWeek(days)" class="nav-link py-2 px-4"
                                [class.active]="days.active">
                                {{days.name}}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light btn-elevate mr-2" (click)="modal.dismiss()">Cancel</button>
        <ng-container *ngIf="schedule">

            <button type="submit" [disabled]="!anyDayActive()" class="btn btn-primary btn-elevate"
                (click)="save()">Save</button>
        </ng-container>
    </div>
</div>