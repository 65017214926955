import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RestaurantScheduleModel } from 'src/app/core/models/restaurant.model';
import { WeekDaysArray } from 'src/app/core/app.constants';

@Component({
  selector: 'app-manage-schedule-popup',
  templateUrl: './manage-schedule-popup.component.html',
  styleUrls: ['./manage-schedule-popup.component.scss']
})
export class ManageSchedulePopupComponent implements OnInit {

  isLoading: boolean;
  showError: boolean;
  invalidTime: boolean;
  weekDays: any[] = WeekDaysArray;


  @Input()
  schedule: RestaurantScheduleModel;
  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
    if (this.schedule.days) {
      this.weekDays.forEach(week => {
        week.active = this.schedule.days.some(d => d == week.value);
      });
    }
  }

  anyDayActive() {
    return this.weekDays.some(d => d.active == true);
  }

  handleWeek(day) {
    day.active = !day.active;
  }

  save() {
    if (this.schedule.startTime && this.schedule.endTime) {
      if (this.schedule.startTime.hour > this.schedule.endTime.hour ||
        (this.schedule.startTime.hour == this.schedule.endTime.hour &&
          this.schedule.startTime.minute >= this.schedule.endTime.minute)
      ) {
        this.invalidTime = true;
        return;
      } else {
        this.schedule.days = this.weekDays.filter(f => f.active == true).map(w => w.value);
        this.modal.close(this.schedule);
        return;
      }
    }
    this.showError = true;
  }

}
