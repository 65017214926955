import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirstLetterPipe } from './pipes/first-letter.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { TimeFormatterPipe } from './pipes/time-formatter.pipe';

@NgModule({
  declarations: [FirstLetterPipe, SafePipe, TimeFormatterPipe],
  imports: [CommonModule],
  exports: [FirstLetterPipe, SafePipe, TimeFormatterPipe],
})
export class CoreModule { }
